<script setup lang="ts">
const { modules, moduleId } = useModules()

const { activeModule, items } = useNavigation(modules)
</script>

<template>
  <UDropdown
    v-slot="{ open }"
    mode="hover"
    :items="items"
    class="w-full"
    :ui="{ width: 'min-w-max' }"
    :popper="{ strategy: 'absolute' }"
  >
    <UButton
      color="gray"
      variant="ghost"
      :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      class="w-full"
    >
      <!-- :trailing-icon="`${open ? 'i-mdi-chevron-up' : 'i-mdi-chevron-down'}`"
    > -->
      <!-- <UIcon :name="activeModule?.icon" size="2xs" /> -->
      <h2 class="text-gray-900 dark:text-white text-lg font-semibold truncate">
        {{ activeModule?.name }}
      </h2>
    </UButton>
  </UDropdown>
</template>
